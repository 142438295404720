import Dropdown from "@/common/components/dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import { labelAscComparator } from "@/common/functions/option";
import { teamUserOptionLabel } from "@/common/functions/teamUserOptionLabel";
import { useAppSelector } from "@/redux/store";
import { KnAiAgentBookingModeEnum } from "@/services/generated";
import { Box, FormControl } from "@mui/material";
import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import * as yup from "yup";
import { AiAgentForm } from "../../..";

const BOOKING_MODE_OPTIONS = [
  {
    label: "Round Robin",
    value: KnAiAgentBookingModeEnum.RoundRobin,
  },
  {
    label: "1:1",
    value: "ONE_TO_ONE",
  },
];

type BookingWithOption = {
  label: string;
  value: string;
};

const schema = yup.object().shape({
  bookingMode: yup.string().required("Booking Mode is required"),
  bookingWith: yup.array().of(yup.string()).required("Booking With is required"),
});

// Type of form
export type BookMeetingFormType = yup.InferType<typeof schema>;

export default function BookMeetingForm() {
  const teamUsers = useAppSelector(({ team }) => team.listUsers.users);

  // Create form
  const form = useFormContext<AiAgentForm>();

  const { control, watch } = form;

  const bookingMode = watch("booking_mode");

  const bookingWithOptions: BookingWithOption[] = useMemo(() => {
    return (teamUsers || [])
      .filter((u) => u.calendarConnected)
      .map((u) => {
        return {
          label: teamUserOptionLabel(u),
          value: u.user_id!,
        };
      })
      .sort(labelAscComparator);
  }, [teamUsers]);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <FormControl>
          <Controller
            name="booking_mode"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <FormLabel label={"Booking Mode"} aria-required />
                <Dropdown
                  options={BOOKING_MODE_OPTIONS}
                  value={field.value}
                  onChange={(o) => field.onChange(o.value)}
                  error={fieldState.error?.message}
                />
              </>
            )}
          />
        </FormControl>
        {bookingMode === "ONE_TO_ONE" && (
          <FormControl>
            <Controller
              name="booking_with"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormLabel label={"Booking With"} aria-required />
                  <Dropdown
                    value={field.value}
                    ref={field.ref}
                    isMulti
                    options={bookingWithOptions}
                    onChange={(options) => {
                      field.onChange(options.map((option: any) => option.value));
                    }}
                    error={fieldState.error?.message}
                  />
                </>
              )}
            />
          </FormControl>
        )}
      </Box>
    </Box>
  );
}
