import { combineReducers } from "@reduxjs/toolkit";
import listCallsReducer from "./listCalls";
import listAgentsReducer from "./listAgents";
import upsertAgentReducer from "./upsertAgent";
import callToActionsReducer from "./callToActions";

const aiVoiceReducer = combineReducers({
  listCalls: listCallsReducer,
  listAgents: listAgentsReducer,
  upsertAgent: upsertAgentReducer,
  callToActions: callToActionsReducer,
});

export default aiVoiceReducer;
