import toast from "@/lib/toast";
import Services from "@/services";
import { AiAgentPhoneNumberListResponse, KnAiAgentRequest, KnVoice } from "@/services/generated";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

type State = {
  voices?: KnVoice[];
  isLoadingVoices?: boolean;
  phones?: AiAgentPhoneNumberListResponse;
  isLoadingPhones?: boolean;
  faqId?: string;
  isLoadingFaq?: boolean;
  loading?: boolean;
  error: string | null;
};

const initialState: State = {
  error: null,
};

export const getAgent = createAsyncThunk("aiVoice/getAgent", async (id: string, { rejectWithValue }) => {
  try {
    const response = await Services.AiVoice.getAiAgent(id);

    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const createAgent = createAsyncThunk(
  "aiVoice/createAgent",
  async (body: KnAiAgentRequest, { rejectWithValue }) => {
    try {
      const response = await Services.AiVoice.createAiAgent(body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateAgent = createAsyncThunk(
  "aiVoice/updateAgent",
  async ({ id, data }: { id: string; data: KnAiAgentRequest }, { rejectWithValue }) => {
    try {
      const response = await Services.AiVoice.updateAiAgent(id, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const listPhoneNumbers = createAsyncThunk(
  "aiVoice/listPhoneNumbers",
  async ({ pageNumber, pageSize }: { pageNumber: number; pageSize: number }) => {
    const response = await Services.AiVoice.listTwilioPhoneNumbers(pageNumber, pageSize ?? 10);
    return response.data;
  },
);

export const listVoices = createAsyncThunk("aiVoice/listVoices", async () => {
  const response = await Services.AiVoice.lookupAiVoices();
  return response.data;
});

export const processFaqUrl = createAsyncThunk("aiVoice/processFaqUrl", async (url: string) => {
  const response = await Services.Faq.crawl({ url });
  return response.data;
});

const upsertAgent = createSlice({
  name: "upsertAgent",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createAgent.pending, (state) => {
        state.loading = true;
      })
      .addCase(createAgent.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(createAgent.rejected, (state, { payload }) => {
        state.loading = false;
      })

      .addCase(updateAgent.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(updateAgent.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(updateAgent.rejected, (state, { payload }) => {
        state.loading = false;
      })

      .addCase(getAgent.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getAgent.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(getAgent.rejected, (state, { payload }) => {
        state.loading = false;
      })

      .addCase(listPhoneNumbers.pending, (state, { payload }) => {
        state.isLoadingPhones = true;
      })
      .addCase(listPhoneNumbers.fulfilled, (state, { payload }) => {
        //TWILIO only allows us to return max 100 fields in a search, so created that to handle it
        state.isLoadingPhones = false;
        const phoneListStored = state.phones?.list || [];
        const phoneAlreadyExists = () => {
          if (payload?.list?.length) return phoneListStored?.find((phone) => phone.number === payload.list![0].number);
          else return false;
        };

        if (phoneAlreadyExists()) return;
        state.phones = {
          list: [...phoneListStored, ...(payload.list || [])],
          nextPageUri: payload.nextPageUri,
        };
        state.isLoadingPhones = false;
      })
      .addCase(listPhoneNumbers.rejected, (state, { payload }) => {
        state.isLoadingPhones = false;
        toast.error("Something went wrong!");
      })

      .addCase(listVoices.pending, (state, { payload }) => {
        state.isLoadingVoices = true;
      })
      .addCase(listVoices.fulfilled, (state, { payload }) => {
        state.voices = payload;
        state.isLoadingVoices = false;
      })
      .addCase(listVoices.rejected, (state, { payload }) => {
        state.isLoadingVoices = false;
        toast.error("Something went wrong!");
      })

      .addCase(processFaqUrl.pending, (state, { payload }) => {
        state.isLoadingFaq = true;
      })
      .addCase(processFaqUrl.fulfilled, (state, { payload }) => {
        state.faqId = payload.id;
        state.isLoadingFaq = false;
      });
  },
  reducers: {},
});

export default upsertAgent.reducer;
