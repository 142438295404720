import Services from "@/services";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { TriggerRequest } from "@/services/generated";

export const createTrigger = createAsyncThunk(
  "triggers/createTrigger",
  async (newTrigger: TriggerRequest, { rejectWithValue }) => {
    try {
      const createdTrigger = await Services.Triggers.createTrigger(newTrigger);
      return createdTrigger.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
