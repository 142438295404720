import Dropdown from "@/common/components/dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import Input from "@/common/components/input";
import { useAppSelector } from "@/redux/store";
import { KnAiAgentRequestTransferEnum } from "@/services/generated";
import { Box, FormControl } from "@mui/material";
import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { AiAgentForm } from "../../..";

const schema = yup.object().shape({
  transfer: yup.string().required("Transfer is required"),
});

export type TransferFormType = yup.InferType<typeof schema>;

export default function TransferForm() {
  const { t } = useTranslation();

  const form = useFormContext<AiAgentForm>();

  const { control } = form;

  const transferPhoneNumberBilling = useAppSelector(
    ({ settings }) => settings.teamAiVoice.active?.transferPhoneNumberBilling,
  );
  const transferPhoneNumberSales = useAppSelector(
    ({ settings }) => settings.teamAiVoice.active?.transferPhoneNumberSales,
  );
  const transferPhoneNumberSupport = useAppSelector(
    ({ settings }) => settings.teamAiVoice.active?.transferPhoneNumberSupport,
  );

  const transferOptions = useMemo(() => {
    const options = [];
    if (transferPhoneNumberSales) {
      options.push({
        label: "Sales",
        value: KnAiAgentRequestTransferEnum.Sales,
      });
    }
    if (transferPhoneNumberBilling) {
      options.push({
        label: "Billing",
        value: KnAiAgentRequestTransferEnum.Billing,
      });
    }
    if (transferPhoneNumberSupport) {
      options.push({
        label: "Support",
        value: KnAiAgentRequestTransferEnum.Support,
      });
    }
    return options;
  }, [transferPhoneNumberBilling, transferPhoneNumberSupport, transferPhoneNumberSales]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <FormControl sx={{ width: "100%" }}>
        <Controller
          name="transfer"
          control={control}
          render={({ field, fieldState }) => (
            <>
              <FormLabel label={t("callFlow.transfer")} aria-required />
              <Dropdown
                options={transferOptions}
                value={field.value}
                onChange={(o) => field.onChange(o.value)}
                error={fieldState.error?.message}
              />
            </>
          )}
        />
      </FormControl>
      <FormControl sx={{ width: "100%" }}>
        <Controller
          name="transfer_phrase"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label={"Transfer Phrase"} aria-required />
              <Input
                {...field}
                placeholder="Transfer Phrase"
                onChange={field.onChange}
                error={fieldState.error?.message}
                multiline
                rows={2}
              />
            </FormControl>
          )}
        />
      </FormControl>
    </Box>
  );
}
