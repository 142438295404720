import { useEffect, useState } from "react";
import { useAppDispatch } from "@/redux/store";
import { getIsConnectedTwilio } from "@/redux/reducers/ai-voice/getIsConnectedTwilio";
import { batch } from "react-redux";
import { searchWebhook } from "@/redux/reducers/settings/company/crm/webhook/search";
import { v4 as uuidv4 } from "uuid";
import { TeamIntegrationTableDataType } from "@/pages/admin/teams/view/team-integration-table";

enum IntegrationCategory {
  Communications = "Communications",
  InboundLeads = "Inbound Leads",
}

const useGetIntegrations = (): TeamIntegrationTableDataType[] => {
  const dispatch = useAppDispatch();
  const [isConnectedTwilio, setIsConnectedTwilio] = useState(false);
  const [hasWebhooks, setHasWebhooks] = useState(false);
  useEffect(() => {
    batch(async () => {
      dispatch(searchWebhook({ pageNumber: 1 })).then((res: any) => {
        setHasWebhooks(res.data?.results.length > 0);
      });
      dispatch(getIsConnectedTwilio())
        .then((res) => {
          setIsConnectedTwilio(res.payload);
        })
        .catch((err) => {
          console.error(err);
        });
    });
  }, [dispatch]);

  const integrations: TeamIntegrationTableDataType[] = [
    {
      id: uuidv4(),
      name: "Twilio",
      category: IntegrationCategory.Communications,
      status: isConnectedTwilio,
    },
    {
      id: uuidv4(),
      name: "Webhooks",
      category: IntegrationCategory.InboundLeads,
      status: hasWebhooks,
    },
  ];
  return integrations;
};

export default useGetIntegrations;
