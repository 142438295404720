import { KnAiAgentCallToActionEnum } from "@/services/generated";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type CallToActionType = BookMeetingCTA | TransferCTA | NoneCTA;

export interface NoneCTA {
  label: "";
  value: "NONE";
}

export interface BookMeetingCTA {
  label: "Book a Meeting";
  value: "BOOK_MEETING";
  bookingMode?: string;
  bookingWith?: string;
}

export interface TransferCTA {
  label: "Live Transfer";
  value: "LIVE_TRANSFER";
  transfer?: string;
}

const callToActions: CallToActionType[] = [
  {
    label: "Book a Meeting",
    value: KnAiAgentCallToActionEnum.BookMeeting,
  },
  {
    label: "Live Transfer",
    value: KnAiAgentCallToActionEnum.LiveTransfer,
  },
];

type State = {
  callToActionToggle: boolean;
  callToActions: CallToActionType[];
  callToActionOptions: CallToActionType[];
};

const initialState: State = {
  callToActionToggle: false,
  callToActions: [],
  callToActionOptions: callToActions,
};

const updateOptions = (state: State) => {
  state.callToActionOptions = callToActions.filter(
    (option) => !state.callToActions.some((cta) => cta.value === option.value),
  );
};

export const resetCallToActionsUtil = (state: State) => {
  state.callToActions = [];
  state.callToActionOptions = callToActions;
};

export const addCallToActionUtil = (state: State) => {
  const nextCallToAction = callToActions.find(
    (option) => !state.callToActions.some((cta) => cta.value === option.value),
  );

  /* if (nextCallToAction) {
    state.callToActions.push({
      label: nextCallToAction.label,
      value: nextCallToAction.value,
    });
  } */

  updateOptions(state);
};

const callToActionsSlice = createSlice({
  name: "callToActions",
  initialState,
  reducers: {
    resetCallToActions: resetCallToActionsUtil,
    addCallToAction: addCallToActionUtil,
    removeCallToAction(state, action: PayloadAction<CallToActionType>) {
      if (state.callToActions.length === 1) {
        resetCallToActionsUtil(state);
        state.callToActionToggle = false;

        return;
      }

      state.callToActions = state.callToActions.filter((cta) => cta.value !== action.payload.value);

      updateOptions(state);
    },
    updateCallToAction(
      state,
      action: PayloadAction<{ newCallToAction: CallToActionType; oldCallToAction: CallToActionType }>,
    ) {
      const { newCallToAction, oldCallToAction } = action.payload;

      const index = state.callToActions.findIndex((cta) => cta.value === oldCallToAction.value);

      const newCallToActions = [...state.callToActions];

      newCallToActions[index] = newCallToAction;

      state.callToActions = newCallToActions;

      updateOptions(state);
    },
    toggleCallToAction(state) {
      state.callToActionToggle = !state.callToActionToggle;

      if (state.callToActionToggle) {
        addCallToActionUtil(state);
      } else {
        resetCallToActionsUtil(state);
      }
    },
    promoteToPrimary(state, action: PayloadAction<CallToActionType>) {
      const index = state.callToActions.findIndex((cta) => cta.value === action.payload.value);
      const primary = state.callToActions[0];

      state.callToActions[0] = state.callToActions[index];
      state.callToActions[index] = primary;
    },
  },
});

export const {
  resetCallToActions,
  addCallToAction,
  removeCallToAction,
  updateCallToAction,
  toggleCallToAction,
  promoteToPrimary,
} = callToActionsSlice.actions;

export default callToActionsSlice.reducer;
