import Button from "@/common/components/button";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import PageHeader from "@/common/components/page-header/PageHeader";
import routes from "@/common/constants/routes";
import toast from "@/lib/toast";
import { getImportData, hideSeqUnEnrollDialog } from "@/redux/reducers/dataImport/slices/list";
import { unenrollSequenceByLeadSourceTriggerId } from "@/redux/reducers/sequences/slices/listSequences";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { KnCompanyImportJobImportJobTypeEnum } from "@/services/generated";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DataImportFilters from "./filters";
import DataImportTable from "./table";
import SeqUnEnrollDialog from "./un-enroll-sequence/SeqUnEnrollDialog";
import ViewLeadSourceDrawer from "./view";

export type DataImportFiltersInput = {
  search?: string;
  objectType?: KnCompanyImportJobImportJobTypeEnum;
  sortBy?: string;
  sortDirection?: string;
};

export default function DataImport() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [filters, setFilters] = useState<DataImportFiltersInput>({});
  const loading = useAppSelector((selector) => selector.dataImport.list.loading);
  const isShowSeqUnEnrollDialog = useAppSelector((selector) => selector.dataImport.list.isShowSeqUnEnrollDialog);

  const methods = useForm({});

  useEffect(() => {
    dispatch(getImportData(filters));
  }, [dispatch, filters]);

  const handleGoToNewImport = () => {
    navigate(routes.dataImport.import.path);
  };

  const handleCloseUnEnrollDialog = () => {
    dispatch(hideSeqUnEnrollDialog());
  };
  const handleSeqUnEnroll = (leadSourceTriggerId: string) => {
    dispatch(unenrollSequenceByLeadSourceTriggerId(leadSourceTriggerId))
      .unwrap()
      .then(() => {
        dispatch(hideSeqUnEnrollDialog());
        toast.success("Lead Source was unenrolled in a sequence!");
      })
      .catch((e) => {
        console.error(e);
        toast.error("Something went wrong!");
      });
  };

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <PageHeader
          title="Data Import"
          actions={
            <Button data-testid="openTaskDrawer" variant="primary" onClick={handleGoToNewImport}>
              New Import
            </Button>
          }
          filters={<DataImportFilters filters={filters} setFilters={setFilters} />}
        />

        {loading ? <LoadingSpinner /> : <DataImportTable filters={filters} setFilters={setFilters} />}
        <SeqUnEnrollDialog
          isOpen={isShowSeqUnEnrollDialog}
          handleClose={handleCloseUnEnrollDialog}
          handleSubmit={handleSeqUnEnroll}
        />
        <ViewLeadSourceDrawer />
      </Box>
    </FormProvider>
  );
}
