/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import Services from "@/services";
import { AxiosResponse } from "axios";
import { ConsentDTO } from "@/services/generated";

export const getIsConnectedUserGmail = createAsyncThunk(
  "oauth/isConnectedUserGmail",
  async (_, { rejectWithValue, dispatch }): Promise<AxiosResponse<boolean>> => {
    try {
      return await Services.Consent.isConnectedUserGmail();
    } catch (error: any) {
      return error;
    }
  },
);

export const getIsConnectedUserOutlook = createAsyncThunk(
  "oauth/isConnectedUserOutlook",
  async (_, { rejectWithValue, dispatch }): Promise<AxiosResponse<boolean>> => {
    try {
      return await Services.Consent.isConnectedUserOutlook();
    } catch (error: any) {
      return error;
    }
  },
);

export const listConnectedTeamShared = createAsyncThunk(
  "oauth/listConnectedTeamShared",
  async (_, { rejectWithValue, dispatch }): Promise<AxiosResponse<ConsentDTO[]>> => {
    try {
      return await Services.Consent.listConnectedTeamShared();
    } catch (error: any) {
      return error;
    }
  },
);

export const getIsGoogleCalendarAuthorized = createAsyncThunk(
  "oauth/getIsGoogleCalendarAuthorized",
  async (_, { rejectWithValue, dispatch }): Promise<AxiosResponse<any>> => {
    try {
      return await Services.Calendar.isGoogleCalendarAuthorized();
    } catch (error: any) {
      return error;
    }
  },
);

export const getIsOutlookCalendarAuthorized = createAsyncThunk(
  "oauth/getIsGoOutlookCalendarAuthorized",
  async (_, { rejectWithValue, dispatch }): Promise<AxiosResponse<boolean>> => {
    try {
      return await Services.Calendar.isGoOutlookCalendarAuthorized();
    } catch (error: any) {
      return error;
    }
  },
);

export const getIsCRMHubspotAuthorized = createAsyncThunk(
  "oauth/getIsCRMHubspotAuthorized",
  async (_, { rejectWithValue, dispatch }): Promise<AxiosResponse<any>> => {
    try {
      return await Services.Crm.isHubspotAuthorized();
    } catch (error: any) {
      return error;
    }
  },
);
