import { DataImportFiltersInput } from "@/pages/data-import";
import Services from "@/services";
import {
  KnFilter2,
  KnFilterOption2OperatorEnum,
  KnFirestoreImportJob,
  KnFirestoreImportJobImportJobTypeEnum,
  KnLookupRequest2SortDirectionEnum,
  SequenceRequestLeadSourceTrigger,
} from "@/services/generated";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

type State = {
  isShowSeqEnrollDialog: boolean;
  isShowSeqUnEnrollDialog: boolean;
  seqEnrollLeadSourceIdList: string[];
  seqEnrollPending: boolean;
  data?: KnFirestoreImportJob[];
  importType: KnFirestoreImportJobImportJobTypeEnum;
  loading: boolean;
  currentLeadSource?: KnFirestoreImportJob;
  viewDrawerIsOpen?: boolean;
};

const initialState: State = {
  viewDrawerIsOpen: false,
  isShowSeqEnrollDialog: false,
  isShowSeqUnEnrollDialog: false,
  seqEnrollLeadSourceIdList: [],
  seqEnrollPending: false,
  importType: "" as KnFirestoreImportJobImportJobTypeEnum,
  loading: false,
};

export const triggerSeqEnrollLeadSourceId = createAsyncThunk(
  "dataImport/triggerSequencesByLeadSourceId",
  async (request: SequenceRequestLeadSourceTrigger, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Sequences.triggerSequencesByLeadSourceId(request);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getImportData = createAsyncThunk(
  "dataImport/getImportData",
  async (input: DataImportFiltersInput, { rejectWithValue, dispatch }) => {
    try {
      const filters: KnFilter2[] = [];

      if (input.objectType) {
        filters.push({
          property: "importJobType",
          options: [
            {
              operator: KnFilterOption2OperatorEnum.Contains,
              values: [input.objectType],
            },
          ],
        });
      }

      if (input.search) {
        filters.push({
          property: "importJobName",
          options: [
            {
              operator: KnFilterOption2OperatorEnum.StartsWith,
              values: [input.search],
            },
          ],
        });
      }

      const response = await Services.DataImport.lookupFirestoreImportJobs({
        filters,
        pageSize: 10,
        sortBy: "createdAt",
        sortDirection:
          (input.sortDirection as KnLookupRequest2SortDirectionEnum) ?? KnLookupRequest2SortDirectionEnum.Desc,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const dataImportListSlice = createSlice({
  name: "dataImport/list",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getImportData.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getImportData.fulfilled, (state, { payload }) => {
        state.data = payload.results;
        state.loading = false;
      })
      .addCase(getImportData.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(triggerSeqEnrollLeadSourceId.pending, (state, { payload }) => {
        state.seqEnrollPending = true;
      })
      .addCase(triggerSeqEnrollLeadSourceId.fulfilled, (state, { payload }) => {
        state.seqEnrollPending = false;
      });
  },
  reducers: {
    setCurrentLeadSource: (state, action) => {
      state.currentLeadSource = action.payload;
    },
    openViewDrawer: (state, action) => {
      state.viewDrawerIsOpen = true;
    },
    closeViewDrawer: (state, action) => {
      state.viewDrawerIsOpen = false;
    },
    setImportType: (state, action) => {
      state.importType = action.payload;
    },
    showSeqEnrollDialog: (state) => {
      state.isShowSeqEnrollDialog = true;
    },
    hideSeqEnrollDialog: (state) => {
      state.isShowSeqEnrollDialog = false;
    },
    showSeqUnEnrollDialog: (state) => {
      state.isShowSeqUnEnrollDialog = true;
    },
    hideSeqUnEnrollDialog: (state) => {
      state.isShowSeqUnEnrollDialog = false;
    },
    setSeqEnrollLeadSourceIdList: (state, action) => {
      state.seqEnrollLeadSourceIdList = action.payload;
    },
  },
});

export const {
  setImportType,
  showSeqEnrollDialog,
  hideSeqEnrollDialog,
  setSeqEnrollLeadSourceIdList,
  setCurrentLeadSource,
  showSeqUnEnrollDialog,
  hideSeqUnEnrollDialog,
  openViewDrawer,
  closeViewDrawer,
} = dataImportListSlice.actions;

export default dataImportListSlice.reducer;
