export const teamIntegrationWrapper = {
  marginTop: "1.5rem",
  display: "flex",
  flexDirection: "column",
};

export const teamIntegrationHeader = {
  fontWeight: "600",
  marginBottom: "1rem",
};
