import { getIsGoogleCalendarAuthorized, getIsOutlookCalendarAuthorized } from "@/redux/reducers/oauth/get";
import { useAppDispatch } from "@/redux/store";
import { useEffect, useState } from "react";
import { batch } from "react-redux";

const useGetCalAuth = (connected: boolean) => {
  const dispatch = useAppDispatch();
  const [hasEverConnectedCalendar, setHasEverConnectedCalendar] = useState(false);

  useEffect(() => {
    if (!connected) {
      batch(async () => {
        // TODO: fix types
        const { payload: gPayload }: any = await dispatch(getIsGoogleCalendarAuthorized());
        const { payload: oPayload }: any = await dispatch(getIsOutlookCalendarAuthorized());

        setHasEverConnectedCalendar(gPayload?.data || oPayload?.data);
      });
    }
    if (connected) {
      setHasEverConnectedCalendar(true);
    }
  }, [connected, dispatch]);
  return hasEverConnectedCalendar;
};

export default useGetCalAuth;
