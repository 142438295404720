import Dropdown from "@/common/components/dropdown";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import { usePagination } from "@/common/components/pagination/usePagination";
import Searchbar from "@/common/components/searchbar/Searchbar";
import TsTable from "@/common/components/ts-table";
import { Roles } from "@/common/constants/roles";
import { getTeamMinutes, getTeamUsers } from "@/redux/reducers/admin/teams/slices/getTeams";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { TeamUser } from "@/types/admin";
import { SquaresPlusIcon } from "@heroicons/react/24/outline";
import { Box, Typography } from "@mui/material";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { TeamUsersColumns } from "./users-table/columns";
import LinearProgressBar from "@/common/components/LinearProgressBar";
import { Loading } from "@kennected/components";
import ViewRowBottomDisplay from "./ViewRowBottomDisplay";
import { resetSelectedUser, sendResetUserPassword } from "@/redux/reducers/admin/users/slices/reset-passoword";
import toast from "@/lib/toast";

import { CheckCircleOutline, ExclamationCircle, X } from "heroicons-react";
import TeamIntegrationTable from "./team-integration-table";
import { teamIntegrationHeader, teamIntegrationWrapper } from "./TeamInfoStyles";
interface TeamInfoProps {
  teamId: string;
}

export default function TeamInfo({ teamId }: TeamInfoProps) {
  const { selectedTeamUsers, loadingUsers, totalUsers, teamTotalMinutes, teamMinutesRemaining, loadingMinutes } =
    useAppSelector((state) => state.teams.getTeams);

  const { selectedRowId, selectedUserName, selectedUserEmail } = useAppSelector(
    (state) => state.teams.resetUserPassword,
  );

  const { onPaginationChange, pagination } = usePagination();
  const [nameFilter, setNameFilter] = useState("");
  const [debouncedNameFilter, setDebouncedNameFilter] = useState("");
  const [roleFilter, setRoleFilter] = useState("");

  const dispatch = useAppDispatch();

  const setDebounced = useMemo(
    () =>
      debounce((value) => {
        setDebouncedNameFilter(value);
      }, 500),
    [],
  );

  useEffect(() => {
    dispatch(
      getTeamUsers({
        teamId,
        filters: {
          page: pagination.pageIndex,
          pageSize: pagination.pageSize,
          filters: [
            {
              options: [{ operator: "equal", values: [debouncedNameFilter] }],
              property: "name",
            },
            ...(roleFilter
              ? [
                  {
                    options: [
                      {
                        values: [roleFilter],
                      },
                    ],
                    property: "role",
                  },
                ]
              : []),
          ],
        },
      }),
    );
  }, [debouncedNameFilter, dispatch, pagination.pageIndex, pagination.pageSize, roleFilter, teamId]);

  useEffect(() => {
    dispatch(getTeamMinutes(teamId));
  }, [dispatch, teamId]);

  const handleConfirmResetUserPassword = async () => {
    dispatch(sendResetUserPassword(selectedUserEmail)).then((result) => {
      const messageSentSuccessfully = result.meta.requestStatus === "fulfilled";
      if (messageSentSuccessfully) {
        toast.success(
          <span>
            We’ve sent a link to user <strong>{selectedUserName}</strong> ({selectedUserEmail}) to reset their password.
          </span>,
          {
            icon: (
              <CheckCircleOutline
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
            ),
            closeButton: () => (
              <X
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            ),
            style: {
              backgroundColor: "#b0e9c2",
              display: "flex",
              alignItems: "center",
              minHeight: "40px",
            },
            className: "custom-toast-position",
          },
        );
      } else {
        toast.error(
          <span>
            Failed to send a password reset link to user <strong>{selectedUserName}</strong> ({selectedUserEmail}).
            Please try again later.
          </span>,
          {
            icon: (
              <ExclamationCircle
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
            ),
            closeButton: () => (
              <X
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            ),
            style: {
              color: "#721c24",
              backgroundColor: "#f8d7da",
              display: "flex",
              alignItems: "center",
              minHeight: "40px",
              width: "644px",
            },
            className: "custom-toast-position",
          },
        );
      }
    });
    dispatch(resetSelectedUser());
  };

  const handleCancelResetUserPassword = () => {
    dispatch(resetSelectedUser());
  };

  const table = useReactTable<TeamUser>({
    data: selectedTeamUsers,
    columns: TeamUsersColumns,
    getCoreRowModel: getCoreRowModel(),
    pageCount: totalUsers,
    manualPagination: true,
    state: {
      pagination: {
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
      },
    },
    onPaginationChange,
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Typography variant="body2" sx={{ fontWeight: "600" }}>
        Summary
      </Typography>
      {loadingMinutes ? (
        <Loading />
      ) : (
        <Box
          sx={{
            width: "396px",
            padding: "16px 24px",
            borderRadius: "8px",
            border: "1px #E7EBED solid",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: "24px",
            height: "124px",
          }}
        >
          <Box sx={{ display: "flex", gap: "16px", flexDirection: "column", marginBottom: "8px", width: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="body2" sx={{ fontWeight: "600" }}>
                Minutes - {teamMinutesRemaining.toFixed(0)} out of {teamTotalMinutes.toFixed(0)}
              </Typography>
            </Box>
            <LinearProgressBar value={(teamMinutesRemaining * 100) / teamTotalMinutes} />
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Box sx={{ maxWidth: "284px", width: "100%" }}>
          <Searchbar
            value={nameFilter || ""}
            onChange={(event) => {
              setDebounced(event.target.value);
              setNameFilter(event.target.value);
            }}
            placeholder="Search for a user"
          />
        </Box>
        <Box sx={{ maxWidth: "204px", width: "100%" }}>
          <Dropdown
            placeholder="Role"
            options={Roles.map((role) => ({
              label: role.roleName,
              value: role.roleCode,
            }))}
            onChange={(option) => {
              setRoleFilter(option.value);
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          position: "relative",
        }}
      >
        <FullLoadingOverlay loading={loadingUsers} overlay overlayDark={false} />
        {selectedTeamUsers && (
          <TsTable
            table={table}
            notFoundProps={{
              title:
                selectedTeamUsers.length === 0 && (nameFilter || roleFilter)
                  ? "No users found that match your criteria"
                  : "No users found",
              description:
                selectedTeamUsers.length === 0 && (nameFilter || roleFilter) ? (
                  <span>Try a different search parameter.</span>
                ) : (
                  <span>No users found.</span>
                ),
              Icon: SquaresPlusIcon,
            }}
            bottomDisplay={(id) =>
              ViewRowBottomDisplay({
                id: id,
                rowSelectedId: selectedRowId,
                handleCancel: handleCancelResetUserPassword,
                handleConfirm: handleConfirmResetUserPassword,
              })
            }
          />
        )}
      </Box>
      <Box sx={teamIntegrationWrapper}>
        <Typography variant="body2" sx={teamIntegrationHeader}>
          Team Integrations
        </Typography>
        <TeamIntegrationTable />
      </Box>
    </Box>
  );
}
