import { createAsyncThunk } from "@reduxjs/toolkit";
import Services from "@/services";

export const getIsConnectedTwilio = createAsyncThunk("aiVoice/getIsConnectedTwilio", async () => {
  try {
    return await Services.AiVoice.isConnectedTwilio();
  } catch (error: any) {
    return error;
  }
});
