import moment from "moment-timezone";

export const dateTimeFormat = (timeInSeconds: number, nanoseconds: number) => {
  const timeInMilliseconds = timeInSeconds * 1000 + Math.round(nanoseconds / 1e6);
  const date = new Date(timeInMilliseconds);
  return date.toLocaleString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
};

export const dateFormatMs = (millis: number) => {
  const date = new Date(millis);

  return dateFormat(date);
};

export const dateFormatTs = ({ seconds, nanos }: { seconds: number; nanos: number }) => {
  const millis = seconds * 1000 + Math.round(nanos / 1e6);
  const date = new Date(millis);

  return dateFormat(date);
};

export const dateFormatStr = (date: string) => {
  return dateFormat(date);
};

export const dateFormat = (date: Date | string) => {
  return moment.tz(date, "UTC").tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A");
};
