import TableCell from "@/common/components/table-cell";
import TableHeader from "@/common/components/table-header";
import { RolesMap } from "@/common/constants/roles";
import { TableColumnEnum } from "@/common/constants/table-column";
import { TeamUser } from "@/types/admin";
import getLoginMethod from "@/utils/getLoginMethod";
import { Box } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import TableActionButton from "./ActionButton";
import CalendarConnectedStatusPill from "./CalendarConnectedStatusPill";

const columnHelper = createColumnHelper<TeamUser>();

export const TeamUsersColumns = [
  columnHelper.accessor("firstName", {
    header: () => <TableHeader>User Full Name</TableHeader>,
    cell: (info) => {
      const row = info.row;
      return <TableCell>{`${info.getValue()} ${row.original.lastName}`}</TableCell>;
    },
  }),

  columnHelper.accessor("email", {
    header: () => <TableHeader>Email</TableHeader>,
    cell: (info) => <TableCell>{info.getValue()}</TableCell>,
  }),

  columnHelper.accessor("userRole", {
    header: () => <TableHeader>Role</TableHeader>,
    cell: (info) => <TableCell>{RolesMap[info.getValue() ?? "SALES_REPRESENTATIVE"]}</TableCell>,
  }),

  columnHelper.accessor("user_id", {
    header: () => <TableHeader>Login Method</TableHeader>,
    cell: (info) => <TableCell>{getLoginMethod(info.getValue())}</TableCell>,
  }),

  columnHelper.accessor("calendarConnected", {
    header: () => <TableHeader>Calendar Status</TableHeader>,
    cell: (info) => (
      <TableCell>
        <CalendarConnectedStatusPill connected={!!info.getValue()} />
      </TableCell>
    ),
  }),

  columnHelper.display({
    id: TableColumnEnum.Actions,
    header: () => <TableHeader sx={{ justifyContent: "center" }}>Actions</TableHeader>,
    cell: (info) => {
      const id = info.row.original.id;
      const userId = info.row.original.user_id;
      const userEmail = info.row.original.email;
      const userName = `${info.row.original.firstName} ${info.row.original.lastName}`;

      return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <TableActionButton
            id={id ?? ""}
            userId={userId ?? ""}
            userEmail={userEmail ?? ""}
            userName={userName ?? ""}
            canResetPassword={getLoginMethod(userId) === "Email"}
          />
        </Box>
      );
    },
  }),
];
