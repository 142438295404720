import Pill from "@/common/components/pill";
import { PillBgColor } from "@/common/components/pill/Pill";
import useGetCalAuth from "@/redux/reducers/oauth/hooks/useGetCalendarAuth";

// TODO: Elevate this enum
enum ConnectedStatus {
  Connected = "Connected",
  Disconnected = "Disconnected",
  NeverConnected = "Never Connected",
}
type IntegrationConnectedStatusPillProps = {
  connected: boolean;
};

const IntegrationConnectedStatusPill = ({ connected }: IntegrationConnectedStatusPillProps) => {
  const hasEverConnectedCalendar = useGetCalAuth(connected);
  let pillColor;
  let pillLabel;

  if (!connected && !hasEverConnectedCalendar) {
    pillLabel = ConnectedStatus.NeverConnected;
    pillColor = PillBgColor.Disabled;
  } else if (connected) {
    pillLabel = ConnectedStatus.Connected;
    pillColor = PillBgColor.Success;
  } else {
    pillLabel = ConnectedStatus.Disconnected;
    pillColor = PillBgColor.Danger;
  }

  return <Pill bgColor={pillColor} label={pillLabel}></Pill>;
};

export default IntegrationConnectedStatusPill;
