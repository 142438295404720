import { Theme } from "@mui/material";
import { StylesConfig } from "react-select";

export const getDropdownStyles = (
  theme: Theme,
  error?: string | null,
  menuMinWidth?: string,

  isMulti?: boolean,
  hasBottomBorders?: boolean,

  hideBorder?: boolean,
  openLeft?: boolean,
  menuMaxHeight = "462px",
  menuMaxWidth = "264px",
): StylesConfig<any, boolean> => ({
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (provided) => ({
    ...provided,
    padding: "12px 16px",
    fontSize: "14px",
    borderRadius: "8px",
    borderColor: theme.palette.commonColors.light,
    color: theme.palette.txt.default,
    border: `${hideBorder ? "0" : "1"}px solid ${
      error ? theme.palette.border.danger : theme.palette.commonColors.light
    }`,
    maxHeight: isMulti ? "auto" : "44px",
    boxShadow: "none",

    borderBottomLeftRadius: hasBottomBorders ? "8px" : "0px",
    borderBottomRightRadius: hasBottomBorders ? "8px" : "0px",

    "&:hover": {
      background: "#F8F8F8",
    },
  }),

  input: (provided) => ({
    ...provided,
    margin: "0px",
    padding: "0px",
  }),

  multiValue: (provided) => ({
    ...provided,

    padding: "0px",
    margin: "2px",
    background: "#B4DFFF",
    borderRadius: "8px",
    color: "#1A2135",
    // MultiValueGeneric
    fontSize: "14px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0px",
  }),

  multiValueRemove: (provided) => ({
    ...provided,
    color: "#1A2135",

    ":hover": {
      color: "#1A2135",
      backgroundColor: "#B2D4FF",
      borderRadius: "8px",
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),

  indicatorsContainer: (provided) => ({
    ...provided,
    alignSelf: isMulti ? "flex-start" : "center",
    marginTop: isMulti ? "6px" : "0px",
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "0px",
    color: theme.palette.midnight.p,

    "&:hover": {
      color: theme.palette.midnight.p,
    },
  }),

  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    marginTop: "2px",
    maxWidth: menuMaxWidth,
    minWidth: menuMinWidth,
    borderRadius: "8px",
    ...(openLeft && {
      right: 0,
    }),
  }),

  menuList: (provided) => ({
    padding: "6px",
    maxHeight: menuMaxHeight,
    overflowY: "auto",
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled
      ? theme.palette.grey.A100
      : state.isFocused
      ? theme.palette.action.hover
      : theme.palette.common.white,
    color: state.isDisabled ? theme.palette.grey.A400 : theme.palette.txt.default,
    margin: "0px",
    fontSize: "14px",
    fontWeight: 400,
    padding: "8px",
    borderBottom: `1px solid ${theme.palette.commonColors.light}`,
    cursor: state.isDisabled ? "not-allowed" : "pointer",

    ":after": {
      content: state.data.subtext && `'${state.data.subtext}'`,
    },

    "&:last-child": {
      borderBottom: "none",
    },

    "&:active": {
      backgroundColor: theme.palette.action.selected,
    },
  }),

  placeholder: (provided) => ({
    ...provided,
    color: theme.palette.txt.placeholder,
    minWidth: "max-content",
    fontSize: "14px",
    margin: "0px",
  }),
});
