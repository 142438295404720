import Button from "@/common/components/button";
import Dropdown from "@/common/components/dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import {
  CallToActionType,
  promoteToPrimary,
  removeCallToAction,
  updateCallToAction,
} from "@/redux/reducers/ai-voice/callToActions";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { KnAiAgentCallToActionEnum } from "@/services/generated";
import { Box, Chip, FormControl } from "@mui/material";
import BookMeetingForm from "./forms/book-meeting";
import TransferForm from "./forms/transfer";
import { Controller, useFormContext } from "react-hook-form";
import { AiAgentForm } from "../..";
import { useEffect, useMemo, useState } from "react";
import { CTAOption } from "./call-to-action-builder";

interface CallToActionProps {
  callToAction: CTAOption;
  isPrimary?: boolean;
  onRemove: () => void;
  setCallToAction: (callToAction: string) => void;
  switchPrimary?: () => void;
  callToActionOptions: CTAOption[];
}

export default function CallToAction({
  callToAction,
  isPrimary = false,
  onRemove,
  setCallToAction,
  callToActionOptions,
  switchPrimary,
}: CallToActionProps) {
  const { setValue } = useFormContext<AiAgentForm>();

  const displayOptions = useMemo(() => {
    const options = [...callToActionOptions];

    if (callToAction.value) {
      options.push(callToAction);
    }

    return options;
  }, [callToAction, callToActionOptions]);

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", paddingBottom: "16px" }}>
        {isPrimary ? (
          <Chip
            label="Primary"
            sx={{
              backgroundColor: "#3373c2",
              color: "#fff",
            }}
          />
        ) : (
          <Box />
        )}
        <Box sx={{ display: "flex" }}>
          {!isPrimary && (
            <Button
              variant="secondary"
              size="sm"
              sx={{
                color: "#3373c2",
                border: "0px",
                fontWeight: "light",
              }}
              onClick={() => {
                switchPrimary?.();
              }}
            >
              Set as Primary
            </Button>
          )}
          <Button
            variant="secondary"
            size="sm"
            sx={{
              color: "#3373c2",
              border: "0px",
              fontWeight: "light",
            }}
            onClick={() => {
              onRemove();
            }}
          >
            Remove
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          border: "1px solid #e7ebed",
          borderRadius: "8px",
          padding: "16px",
          marginBottom: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box>
          <FormLabel label={"Call to Action"} aria-required />
          <Dropdown
            placeholder="Select"
            value={callToAction.value}
            options={displayOptions}
            isDisabled={callToActionOptions.length === 0}
            onChange={(option) => {
              setCallToAction(option.value);

              if (isPrimary) {
                setValue("call_to_action", option.value as never);
              } else {
                if (option.value) {
                  setValue("call_to_action_2", [option.value] as never);
                } else {
                  setValue("call_to_action_2", [] as never);
                }
              }
            }}
          />
        </Box>
        <Box>
          {callToAction.value === KnAiAgentCallToActionEnum.BookMeeting ? (
            <BookMeetingForm />
          ) : callToAction.value === KnAiAgentCallToActionEnum.LiveTransfer ? (
            <TransferForm />
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}
