import {
  closeViewDrawer,
  pauseSequenceById,
  resumeSequenceById,
  unenrollSequence,
} from "@/redux/reducers/prospects/slices/listProspects";
import { useAppDispatch } from "@/redux/store";
import * as React from "react";

import MenuOptions, { MenuOption } from "@/common/components/menu-options/MenuOptions";
import toast from "@/lib/toast";
import { SequenceResponse, SequenceResponseStatusEnum } from "@/services/generated";
import theme from "@/theme/theme";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { ElipseIcon } from "../elipse-icon";

const iconStyles = {
  width: "24px",
  height: "24px",
  stroke: "#1A2135",
};

enum ProspectActionMenuOptionEnum {
  UnEnrollFromSequence = "un_enroll_from_sequence",
  Pause = "pause",
  Resume = "resume",
}

const isPaused = (status: SequenceResponseStatusEnum | undefined): boolean => {
  return (
    status === SequenceResponseStatusEnum.PausedInTeamLevel ||
    status === SequenceResponseStatusEnum.PausedInTemplateLevel ||
    status === SequenceResponseStatusEnum.PausedInProspectLevel
  );
};

const getOptions = (status: SequenceResponse["status"]): MenuOption[] => {
  const baseOptions = [
    {
      label: "Un-enroll From Sequence",
      value: ProspectActionMenuOptionEnum.UnEnrollFromSequence,
      icon: <XCircleIcon style={iconStyles} />,
    },
  ];

  if (isPaused(status)) {
    return [
      {
        label: "Resume Sequence",
        value: "resume",
        icon: <ElipseIcon color={theme.palette.error.light} />,
      },
      ...baseOptions,
    ];
  }

  if (status === "IN_PROGRESS") {
    return [
      {
        label: "Pause Sequence",
        value: "pause",
        icon: <ElipseIcon color={theme.palette.primary.main} />,
      },
      ...baseOptions,
    ];
  }

  return [];
};

type ProspectSequenceActionButtonProps = {
  canUnEnroll?: boolean;
  sequenceId: string;
  status: SequenceResponse["status"];
};

export function ProspectSequenceActionButton({
  sequenceId,
  status,
  canUnEnroll = true,
}: ProspectSequenceActionButtonProps) {
  const options = getOptions(status);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const unenrollFromSequence = () => {
    dispatch(unenrollSequence(sequenceId))
      .unwrap()
      .then(() => {
        toast.success("Prospect was unenrolled in a sequence!");
      })
      .catch((e) => {
        toast.error("Something went wrong!");
      });
  };

  const pauseSequence = () => {
    dispatch(pauseSequenceById(sequenceId))
      .unwrap()
      .then(() => {
        toast.success("Prospect sequence was paused!");
      })
      .catch((e) => {
        toast.error("Something went wrong!");
      });
  };

  const resumeSequence = () => {
    dispatch(resumeSequenceById(sequenceId))
      .unwrap()
      .then(() => {
        toast.success("Prospect sequence was resumed!");
      })
      .catch((e) => {
        toast.error("Something went wrong!");
      });
  };

  if (!options.length) {
    return null;
  }

  return (
    <MenuOptions
      open={open}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
      options={options}
      onClick={(option) => {
        if (option.value === ProspectActionMenuOptionEnum.UnEnrollFromSequence) {
          unenrollFromSequence();
        } else if (option.value === ProspectActionMenuOptionEnum.Pause) {
          pauseSequence();
        } else if (option.value === ProspectActionMenuOptionEnum.Resume) {
          resumeSequence();
        }
        dispatch(closeViewDrawer());
        handleClose();
      }}
    />
  );
}
