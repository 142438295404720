import TableCell from "@/common/components/table-cell";
import TableHeader from "@/common/components/table-header";
import { createColumnHelper } from "@tanstack/react-table";
import { capitalize } from "lodash";
import IntegrationConnectedStatusPill from "./IntegrationConnectedStatusPill";
import { TeamIntegrationTableDataType } from ".";

const columnHelper = createColumnHelper<TeamIntegrationTableDataType>();

enum TableColumnNamesEnum {
  IntegrationName = "integration name",
  Category = "category",
  Status = "status",
}

export const TeamIntegrationColumns = [
  columnHelper.accessor("name", {
    header: () => <TableHeader>{capitalize(TableColumnNamesEnum.IntegrationName)}</TableHeader>,
    cell: (info) => {
      return <TableCell>{info.getValue()}</TableCell>;
    },
  }),

  columnHelper.accessor("category", {
    header: () => <TableHeader>{capitalize(TableColumnNamesEnum.Category)}</TableHeader>,
    cell: (info) => <TableCell>{info.getValue()}</TableCell>,
  }),

  columnHelper.accessor("status", {
    header: () => <TableHeader>{capitalize(TableColumnNamesEnum.Category)}</TableHeader>,
    cell: (info) => (
      <TableCell>
        <IntegrationConnectedStatusPill connected={!!info.getValue()} />
      </TableCell>
    ),
  }),
];
