import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { TeamIntegrationColumns } from "./columns";
import TsTable from "@/common/components/ts-table";
import useGetIntegrations from "@/redux/reducers/oauth/hooks/useGetIntegrations";

export type TeamIntegrationTableDataType = {
  id: string;
  name: string;
  category: string;
  status: boolean;
};

const TeamIntegrationTable = () => {
  const data = useGetIntegrations();

  const table = useReactTable<TeamIntegrationTableDataType>({
    data,
    columns: TeamIntegrationColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return <TsTable table={table} showPagination={false} />;
};

export default TeamIntegrationTable;
