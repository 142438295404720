import Pill from "@/common/components/pill";
import { PillBgColor } from "@/common/components/pill/Pill";
import useGetCalAuth from "@/redux/reducers/oauth/hooks/useGetCalendarAuth";

enum ConnectedStatus {
  Connected = "Connected",
  Disconnected = "Disconnected",
  NeverConnected = "Never Connected",
}
type CalendarConnectedStatusPillProps = {
  connected: boolean;
};

const CalendarConnectedStatusPill = ({ connected }: CalendarConnectedStatusPillProps) => {
  const hasEverConnectedCalendar = useGetCalAuth(connected);
  let pillColor;
  let pillLabel;

  if (!connected && !hasEverConnectedCalendar) {
    pillLabel = ConnectedStatus.NeverConnected;
    pillColor = PillBgColor.Disabled;
  } else if (connected) {
    pillLabel = ConnectedStatus.Connected;
    pillColor = PillBgColor.Success;
  } else {
    pillLabel = ConnectedStatus.Disconnected;
    pillColor = PillBgColor.Danger;
  }

  return (
    <>
      <Pill bgColor={pillColor} label={pillLabel}></Pill>
    </>
  );
};

export default CalendarConnectedStatusPill;
