import styled from "@mui/material/styles/styled";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { PlusCircleOutline } from "heroicons-react";

type BgColor = "success" | "warning" | "danger" | "informative" | "disabled";
export const enum PillBgColor {
  Success = "success",
  Warning = "warning",
  Danger = "danger",
  Informative = "informative",
  Disabled = "disabled",
}

type IconType = "plus" | "envelope" | "message";

export type PillProps = {
  bgColor: BgColor;
  label?: string;
  rounded?: boolean;
  iconType?: IconType;
};

export default function Pill({ label, bgColor, rounded = true, iconType = "plus" }: PillProps) {
  const theme = useTheme();

  const commonStyles = {
    background: bgColor ? theme.palette.commonColors[bgColor] : theme.palette.commonColors.light,
    borderRadius: rounded ? "50px" : "4px",
    color:
      bgColor === "danger" || bgColor === "informative"
        ? theme.palette.commonColors.white
        : theme.palette.commonColors.text,
  };

  const getIcon = (iconType: IconType) => {
    switch (iconType) {
      case "plus":
        return <PlusCircleOutline size={16} />;
      case "envelope":
        return <EnvelopeIcon />;

      case "message":
        return <MessageIcon />;
      default:
        return <PlusCircleOutline size={16} />;
    }
  };

  const StyledPill = styled(Box)(() => ({
    padding: "4px 8px ",
    margin: "0px",
    fontWeight: 500,
    fontSize: "12px",
    letterSpacing: "0.2px",
    lineHeight: "16px",
    width: "fit-content",
    height: "fit-content",
    ...commonStyles,
  }));

  const StyledIconPill = styled(Box)(() => ({
    padding: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    height: "fit-content",
    ...commonStyles,
  }));

  if (label) {
    return <StyledPill>{label}</StyledPill>;
  } else {
    return <StyledIconPill>{getIcon(iconType)}</StyledIconPill>;
  }
}

const EnvelopeIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 5.7794V11.5C1 12.6046 1.89543 13.5 3 13.5H13C14.1046 13.5 15 12.6046 15 11.5V5.7794L9.04819 9.44205C8.40538 9.83762 7.59462 9.83763 6.95181 9.44205L1 5.7794Z"
      fill="#374667"
    />
    <path
      d="M15 4.60522V4.5C15 3.39543 14.1046 2.5 13 2.5H3C1.89543 2.5 1 3.39543 1 4.5V4.60522L7.4759 8.59039C7.79731 8.78818 8.20269 8.78818 8.5241 8.59039L15 4.60522Z"
      fill="#374667"
    />
  </svg>
);

const MessageIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.23224 1.84734C4.78868 1.61849 6.38075 1.5 8.00019 1.5C9.61948 1.5 11.2114 1.61846 12.7677 1.84727C14.0866 2.04118 15 3.1963 15 4.4937V8.5063C15 9.80371 14.0866 10.9588 12.7677 11.1527C12.0036 11.2651 11.2309 11.3508 10.4506 11.409C10.3676 11.4152 10.2954 11.4583 10.2527 11.5224L8.41603 14.2774C8.32329 14.4164 8.16718 14.5 8 14.5C7.83282 14.5 7.67671 14.4164 7.58397 14.2774L5.74728 11.5223C5.7046 11.4583 5.63235 11.4151 5.54939 11.409C4.76905 11.3508 3.99635 11.265 3.23224 11.1527C1.91336 10.9587 1 9.80362 1 8.50623V4.49376C1 3.19637 1.91336 2.04126 3.23224 1.84734ZM4.5 5.5C4.5 5.22386 4.72386 5 5 5H11C11.2761 5 11.5 5.22386 11.5 5.5C11.5 5.77614 11.2761 6 11 6H5C4.72386 6 4.5 5.77614 4.5 5.5ZM5 7C4.72386 7 4.5 7.22386 4.5 7.5C4.5 7.77614 4.72386 8 5 8H8C8.27614 8 8.5 7.77614 8.5 7.5C8.5 7.22386 8.27614 7 8 7H5Z"
      fill="#374667"
    />
  </svg>
);
