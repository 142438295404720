import { closeViewDrawer } from "@/redux/reducers/prospects/slices/listProspects";
import { useAppDispatch } from "@/redux/store";
import * as React from "react";

import MenuOptions from "@/common/components/menu-options/MenuOptions";
import toast from "@/lib/toast";
import { unenrollSequenceByLeadSourceTriggerId } from "@/redux/reducers/sequences/slices/listSequences";
import { XCircleIcon } from "@heroicons/react/24/outline";

const iconStyles = {
  width: "24px",
  height: "24px",
  stroke: "#1A2135",
};

enum ProspectActionMenuOptionEnum {
  UnEnrollFromSequence = "un_enroll_from_sequence",
}

const options = [
  {
    label: "Un-enroll From Sequence",
    value: ProspectActionMenuOptionEnum.UnEnrollFromSequence,
    icon: <XCircleIcon style={iconStyles} />,
  },
];

type ActionButtonProps = {
  canUnEnroll?: boolean;
  leadSourceTriggerId: string;
};

export default function ActionButton({ leadSourceTriggerId, canUnEnroll = true }: ActionButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const unenrollFromSequence = () => {
    dispatch(unenrollSequenceByLeadSourceTriggerId(leadSourceTriggerId))
      .unwrap()
      .then(() => {
        toast.success("Prospect was unenrolled in a sequence!");
      })
      .catch((e) => {
        toast.error("Something went wrong!");
      });
  };
  return (
    <MenuOptions
      open={open}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
      options={options}
      onClick={(option) => {
        if (option.value === ProspectActionMenuOptionEnum.UnEnrollFromSequence) {
          unenrollFromSequence();
          dispatch(closeViewDrawer());
          handleClose();
        }
      }}
    />
  );
}
